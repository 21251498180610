<template>
    <section>
      <div class="venueBox">
        <div class="venueItemBox" v-for="(item, index) in dataList" :key="index">
          <div class="venueImg">
            <img :src="item.itsFiles.length ? item.itsFiles[0].fileUrl : defaultImg" />
            <div class="imgBG"></div>
          </div>
          <div class="venueInfo">
            <div class="venueTitle">{{item.name}}</div>
            <div class="venueTxt">
              <p class="tagVenueSign"> <span>{{item.area}}㎡</span> <span v-if="item.qty>0">{{item.qty}} 间</span></p>
            </div>
            <div class="venueTxt">
              <div class="venuePrice smallWidth">
                <span>参考价</span>
                <span class="colorRed2">{{(item.price || 0) | currencyFormatter}}</span>
                <span class="font10">/间/天</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
    export default {
        name: 'itsRooms',
        props: ['dataList'],
        data () {
            return {
                defaultImg: require('../../../assets/img/room.png')
            }
        }
    }
</script>
<style scoped lang="scss">
    @import "itemStyle";
</style>
