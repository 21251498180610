<template>
    <section>
      <sm_header title='酒店详情' :smHleftEvent='closeWebview'></sm_header>
        <swiper class="swiper" :options="swiperOption">
            <swiper-slide v-for="(item, index) in (venueDetalisInfo.itsFiles && venueDetalisInfo.itsFiles.length ? venueDetalisInfo.itsFiles : itsFiles)" :key="index"><img :src="item.fileUrl"/></swiper-slide>
        </swiper>
        <div class="venueInfo">
            <div class="venueInfoHead">
                <p class="venueTitle">{{venueDetalisInfo.supplierName}}</p>
              <p class="venueSmallTitle">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconshoucang"></use>
                </svg>
                {{venueDetalisInfo.supplierLevel}}
              </p>
            </div>
            <div class="venueDescr" :class="showDescr ? 'showDescr' : 'hiddenDescr'">
                <p>{{venueDetalisInfo.descr}}</p>
                <div class="descrBtn" @click="showDescr = !showDescr">【{{showDescr ? '收起': '全文'}}】</div>
            </div>
        </div>
        <div class="supplierInfo">
            <div class="infoCard">
                <div class="infoTitle">
                    <p>
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-dianhua"></use>
                        </svg>
                        联系电话
                    </p>
                    <p class="phone">{{venueDetalisInfo.mobile}}</p>
                </div>
                <div class="infoBtn">
                    <svg class="icon" aria-hidden="true" @click="dialing(venueDetalisInfo.mobile)">
                        <use xlink:href="#icon-jinru"></use>
                    </svg>
                </div>
            </div>
            <div class="infoCard">
                <div class="infoTitle" @click="goMap">
                    <p>
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-weizhi"></use>
                        </svg>
                        商家地址
                    </p>
                    <p class="phone">{{venueDetalisInfo.address}}</p>
                </div>
            </div>
        </div>
        <div class="venueFacilities">
            <div class="facilitiesItems">
                <div :class="isActive('会场') ? 'active' : ''" @click="selectFacilities = '会场'">会场({{itsVenue.length}})</div>
                <div :class="isActive('客房') ? 'active' : ''" @click="selectFacilities = '客房'">客房({{itsRooms.length}})</div>
                <div :class="isActive('餐饮报价') ? 'active' : ''" @click="selectFacilities = '餐饮报价'">餐饮报价({{itsRestaurant.length}})</div>
            </div>
            <its-venue :dataList="itsVenue" v-show="isActive('会场')"></its-venue>
            <its-rooms :dataList="itsRooms" v-show="isActive('客房')"></its-rooms>
            <its-restaurant :dataList="itsRestaurant" v-show="isActive('餐饮报价')"></its-restaurant>
        </div>

      <div class="largeBtn" v-show="!onlyScan" @click="reserveEvent">预定</div>
    </section>
</template>
<script>
    import itsVenue from './itsVenue'
    import itsRooms from './itsRooms'
    import itsRestaurant from './itsRestaurant'
    export default {
        components: {
            itsVenue,
            itsRooms,
            itsRestaurant
        },
        data () {
            return {
                supplierId: '',
                showDescr: false,
                onlyScan: false,
                venueDetalisInfo: {},
                swiperOption: {
                    spaceBetween: 30,
                    centeredSlides: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    autoplay: true
                },
                selectFacilities: '会场',
                itsVenue: [],
                itsRooms: [],
                itsRestaurant: [],
              itsFiles: [{fileUrl: require('../../../assets/img/deltailsBanner.png')}]
            }
        },
        created() {
            this.supplierId = this.$route.query.supplierId
            this.onlyScan = this.$SmartStorage.get('onlyScan')
            this.getSupplier()
        },
        methods: {
            async getSupplier () {
                let params = {
                    supplierId: this.supplierId
                }
                let res = await this.$service.getSupplier(params)
                if (res && res.success) {
                    this.venueDetalisInfo = res.content
                    this.itsVenue = res.content.itsVenue
                    this.itsRooms = res.content.itsRooms
                    this.itsRestaurant = res.content.itsRestaurant
                }
            },
            closeWebview () {
                this.$router.go(-1)
            },
            dialing(phoneNumber) {
                if(!phoneNumber) return;
                window.location.href = 'tel://' + phoneNumber;
            },
            isActive (type) {
                return this.selectFacilities === type
            },
            goMap () {
                this.$router.push({path:'/map',query: { lat: this.venueDetalisInfo.lat, lng: this.venueDetalisInfo.lng, fromPage: 'venueDetails'}})
            },
            // 预定事件
            reserveEvent() {
              let selectedVenueId = this.$SmartStorage.get('selectedVenueId')
              let maxSelect = this.$SmartStorage.get('maxSelect')
              if (selectedVenueId.includes(this.supplierId)) {
                this.$root.$eventHub.$emit('reserveVenue',true);
                this.$router.back();
                return
              }
              if (selectedVenueId.length >= maxSelect) {
                this.$toast(`最多可选择${maxSelect}家供应商`);
                return
              }
              selectedVenueId.push(this.supplierId)
              this.$SmartStorage.set('selectedVenueId', selectedVenueId)
              this.$root.$eventHub.$emit('reserveVenue',true);
              this.$router.back();
            }
        }
    }
</script>
<style lang="scss" scoped>
    .venueInfo {
        padding: .1rem ;
        .venueInfoHead {
            padding: .1rem 0;
            p {
                font-size: .2rem;
                font-weight: 600;
                color: #000;
                text-align: left;
            }
          .venueSmallTitle {
            color: #FFCD30 ;
            font-size: .12rem;
            .icon {
              width:.15rem;
              height:.15rem;
            }
          }
        }
        .venueDescr {
            position: relative;
            p {
                color: #5B6C76;
                font-size: .14rem;
                line-height: .22rem;
                white-space: pre-line;
                -webkit-line-clamp: 4;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                text-align: left;
                min-height: .88rem;
            }
            .descrBtn {
                position: absolute;
                right: 0;
                bottom: -0.25rem;
                color: #909399;
            }
        }
        .hiddenDescr p{
            overflow: hidden;
        }
        .showDescr p{
            overflow: auto;
            -webkit-line-clamp: inherit;
            min-height: 3rem;
        }
    }
    .supplierInfo {
        padding: .1rem;
        .infoCard {
            border-radius: .04rem;
            box-shadow: 5px 5px .15rem #dedede;
            margin: .1rem 0 .08rem;
            padding: .1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
                text-align: left;
                color: #13161B;
                font-weight: 600;
                &:first-child {
                    color: #90939A;
                    font-size: .16rem;
                    margin-bottom: .05rem;
                    font-weight: inherit;
                }
                .icon {
                    width: .17rem;
                    height: .17rem;
                }
            }
            .infoBtn {
                width: .3rem;
              height: .3rem;
                background: var(--themeColor);
                border-radius: 50%;
                color: #fff;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
                .icon {
                  width: 0.15rem;
                  height: 0.15rem;
                  vertical-align: -0.15em;
                  fill: currentColor;
                  overflow: hidden;
                }
            }
        }
    }
    .facilitiesItems {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        div {
            font-size: .16rem;
            font-weight: 600;
            color: #90939A;
            position: relative;
          min-width: 0.9rem;
        }
        div.active {
            color: #13161B;
            &:after {
              content: '';
              background: var(--themeColor);
              width: 0.2rem;
              height: 5px;
              display: block;
              border-radius: 0.4rem;
              position: absolute;
              left: calc(50% - 0.15rem);
            }
        }
    }
    .largeBtn {
      margin: 0.2rem 0.1rem 0.15rem 0.1rem;
    }
</style>
<style lang="scss">
    .swiper-container {
      margin-top: .44rem;
        width: 100%;
        height: 211px;
        img {
            width: 100%;
            height: 211px;
        }
    }
</style>
