<template>
    <section>
      <div class="venueBox">
        <div class="venueItemBox" v-for="(item, index) in dataList" :key="index">
          <div class="venueImg">
            <img :src="item.itsFiles.length ? item.itsFiles[0].fileUrl : defaultImg" />
            <div class="imgBG"></div>
          </div>
          <div class="venueInfo">
            <div class="venueTitle">{{item.name}}</div>
            <div class="venueTxt">
              <div class="venuePrice smallWidth">
                <div>
                  <p v-for='(list,i) in item.itsMealCategory' :key="i">
                    <span>{{list.name}}  </span>
                    <span v-if="list.people>0">{{list.people}}人  </span>
                    <span v-if="list.price>0" class="colorRed2">{{(list.price || 0)|currencyFormatter}}</span>
                    <span v-if='list.name=="桌餐" && list.price>0' class="font10">/桌</span>
                    <span v-else-if="list.price>0" class="font10">/人</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
    export default {
        name: 'itsRestaurant',
        props: ['dataList'],
        data () {
            return {
                defaultImg: require('../../../assets/img/meal.png')
            }
        }
    }
</script>
<style scoped lang="scss">
    @import "itemStyle";
</style>
