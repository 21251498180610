<template>
    <section>
        <div class="venueBox">
            <div class="venueItemBox" v-for="(item, index) in dataList" :key="index">
              <div class="venueImg">
                <img :src="item.itsFiles.length ? item.itsFiles[0].fileUrl : coverUrl" />
                <div class="imgBG"></div>
              </div>
              <div class="venueInfo">
                <div class="venueTitle">{{item.name}}</div>
                <div class="venueTxt">
                  <p class="tagVenueSign"> <span>{{item.area}}㎡</span></p>
                  <div class="venuePrice smallWidth" v-if="item.itsVenuePrices.length">
                    <div>
                      <div v-for="(list, index) in item.itsVenuePrices" :key="index">
                        <span>参考价</span>
                        <span class="colorRed2">{{(list.price || 0) | currencyFormatter}}</span>
                        <span>/{{list.venueCategory}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'itsVenue',
        props: ['dataList'],
        data () {
            return {
              coverUrl: require('../../../assets/img/venue.png'),
            }
        },
        computed: {
            isWidth () {
                return window.screen.width < 414
            }
        }
    }
</script>
<style scoped lang="scss">
    @import "itemStyle";
</style>
